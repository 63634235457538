"use strict";

module.exports = class home {
	constructor() {
		this.showAllDM();
		this.envies();
		this.rechercheAvancee();
	}

	showAllDM() {
		let container = document.querySelector('.section--dm .container--scroll'),
		btn = container.nextElementSibling;

		btn.addEventListener('click', () => {
			container.removeAttribute('style');
			btn.remove()
		})
	}

	envies() {
		settingsApp.modules.moduleTab.then(() => {
			let Tab = require('modules/tab')
			new Tab({
				header: document.querySelector('.module--tabs'),
				body: document.querySelector('.liste__envies')
			});
		}).catch((e) => {
			console.error(e)
		});
	}

	rechercheAvancee() {
		document.querySelector('[data-recherche]').addEventListener('click', () => {
			try {
				settingsApp.modules.moduleRechercheAvancee
					.then(() => {
						require("modules/moteurRecherche").launch('/MoteurRecherche/SearchEngine')
					});
			} catch (e) {
				console.error(e)
			}
		})
	}
}